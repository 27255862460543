var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", "clients")
    ? _c(
        "div",
        [
          _c("client-list-add-new", {
            attrs: {
              "is-add-new-client-sidebar-active":
                _vm.isAddNewClientSidebarActive,
              "clients-list": _vm.clientsListData
            },
            on: {
              "update:isAddNewClientSidebarActive": function($event) {
                _vm.isAddNewClientSidebarActive = $event
              },
              "update:is-add-new-client-sidebar-active": function($event) {
                _vm.isAddNewClientSidebarActive = $event
              },
              "refetch-data": _vm.refetchData
            }
          }),
          _c(
            "b-card",
            { staticClass: "mb-0", attrs: { "no-body": "" } },
            [
              _c("clients-list-filters", {
                attrs: {
                  "status-filter": _vm.statusFilter,
                  "status-options": _vm.statusOptions,
                  "services-filter": _vm.servicesFilter,
                  "services-options": _vm.servicesOptions
                },
                on: {
                  "update:statusFilter": function($event) {
                    _vm.statusFilter = $event
                  },
                  "update:status-filter": function($event) {
                    _vm.statusFilter = $event
                  },
                  "update:servicesFilter": function($event) {
                    _vm.servicesFilter = $event
                  },
                  "update:services-filter": function($event) {
                    _vm.servicesFilter = $event
                  }
                }
              }),
              _c(
                "div",
                { staticClass: "m-2" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "primary",
                                disabled: this.$store.state.clients
                                  .fetchingClientList
                              },
                              on: { click: _vm.refreshClientList }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "RefreshCcwIcon" }
                              }),
                              _c("span", { staticClass: "text-nowrap ml-1" }, [
                                _vm._v("Refresh")
                              ])
                            ],
                            1
                          ),
                          _vm.$can("create", "clients")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "ml-2",
                                  attrs: { variant: "primary" },
                                  on: {
                                    click: function($event) {
                                      _vm.isAddNewClientSidebarActive = true
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "UserPlusIcon" }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-nowrap ml-1" },
                                    [_vm._v("Add Client")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-end"
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "d-inline-block mr-1",
                              attrs: { placeholder: "Search..." },
                              model: {
                                value: _vm.searchQuery,
                                callback: function($$v) {
                                  _vm.searchQuery = $$v
                                },
                                expression: "searchQuery"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-table", {
                ref: "refClientListTable",
                staticClass: "position-relative",
                attrs: {
                  items: _vm.fetchClients,
                  responsive: "",
                  fields: _vm.tableColumns,
                  "primary-key": "clientname",
                  "sort-by": _vm.sortBy,
                  "show-empty": "",
                  "empty-text": "",
                  "sort-desc": _vm.isSortDirDesc,
                  busy: _vm.isBusy
                },
                on: {
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.isSortDirDesc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.isSortDirDesc = $event
                  },
                  "update:busy": function($event) {
                    _vm.isBusy = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(client_name)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-nowrap" },
                            [
                              _c(
                                "b-link",
                                {
                                  staticClass:
                                    "font-weight-bold d-block text-nowrap",
                                  attrs: {
                                    to: {
                                      name: "apps-clients-view",
                                      params: {
                                        clientname: data.item.clientname
                                      }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(data.item.display_name) + " "
                                  )
                                ]
                              ),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("@" + _vm._s(data.item.clientname))
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(parent_client)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-nowrap" },
                            [
                              data.item.parent_clientname !== undefined &&
                              data.item.parent_clientname !== ""
                                ? _c(
                                    "b-link",
                                    {
                                      staticClass:
                                        "font-weight-bold d-block text-nowrap",
                                      attrs: {
                                        to: {
                                          name: "apps-clients-view",
                                          params: {
                                            clientname:
                                              data.item.parent_clientname
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(data.item.parent_clientname) +
                                          " "
                                      )
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(data.item.parent_clientname) +
                                        " "
                                    )
                                  ])
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(status)",
                      fn: function(data) {
                        return [
                          _c(
                            "b-badge",
                            {
                              staticClass: "text-capitalize",
                              attrs: {
                                pill: "",
                                variant:
                                  "light-" +
                                  _vm.resolveClientStatusVariant(
                                    data.item.status
                                  )
                              }
                            },
                            [_vm._v(" " + _vm._s(data.item.status) + " ")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2217126681
                )
              }),
              _vm.$store.state.clients.fetchingClientList
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-content-center" },
                    [_c("b-spinner", { attrs: { label: "Loading..." } })],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "mx-2 mb-2" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c("label", [_vm._v("Show")]),
                          _c("v-select", {
                            staticClass:
                              "per-page-selector d-inline-block mx-50",
                            attrs: {
                              dir: _vm.$store.state.appConfig.isRTL
                                ? "rtl"
                                : "ltr",
                              options: _vm.perPageOptions,
                              clearable: false
                            },
                            model: {
                              value: _vm.perPage,
                              callback: function($$v) {
                                _vm.perPage = $$v
                              },
                              expression: "perPage"
                            }
                          }),
                          _c("label", [_vm._v("entries")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center justify-content-sm-start",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "Showing " +
                                _vm._s(_vm.dataMeta.from) +
                                " to " +
                                _vm._s(_vm.dataMeta.to) +
                                " of " +
                                _vm._s(_vm.dataMeta.of) +
                                " entries"
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center justify-content-sm-end",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("b-pagination", {
                            staticClass: "mb-0 mt-1 mt-sm-0",
                            attrs: {
                              "total-rows": _vm.totalClients,
                              "per-page": _vm.perPage,
                              "first-number": "",
                              "last-number": "",
                              "prev-class": "prev-item",
                              "next-class": "next-item"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prev-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronLeftIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "next-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronRightIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1308952388
                            ),
                            model: {
                              value: _vm.currentPage,
                              callback: function($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }